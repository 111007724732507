<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="selectForm" v-model="selectForm" class="select-form">
          <div>
            <el-form-item label="组织名称">
              <el-input v-model.trim="selectForm.orgName" placeholder="请输入组织名称"
                        maxlength="64" @keyup.enter.native="submitForm()"/>
            </el-form-item>
            <el-form-item label="组织税号">
              <el-input v-model.trim="selectForm.taxNo" placeholder="请输入组织税号"
                        maxlength="32" @keyup.enter.native="submitForm()"/>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @select="handleSelect" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div class="content-title">
        <span>数据模板列表</span>
        <el-button type="primary" @click="handleManualNumber">手动取数</el-button>
      </div>
      <div class="content-tables">
        <div class="content-table">
          <el-table
            :data="tableData"
            stripe
            border
            v-loading="tableLoading"
            :row-key="getRowKeys"
            :header-cell-style="handleHeaderCellStyle"
            :cell-style="cellStyle"
            style="width: 100%;margin-bottom: 20px;"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          >
            <el-table-column prop="declarePeriod" label="所属税期" width="180"
                             align="left"></el-table-column>
            <el-table-column prop="orgName" label="组织名称" min-width="180" align="left"></el-table-column>
            <el-table-column prop="taxNo" label="组织税号" width="200"></el-table-column>
            <el-table-column prop="created" label="生成时间" width="180"></el-table-column>
            <el-table-column prop="updated" label="更新时间" width="180"></el-table-column>
            <el-table-column prop="operate" label="操作" fixed="right" width="180">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetail(scope.row)" :disabled="!scope.row.tableId">详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="content-page">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="page.current"
                         :page-sizes="pageSizes"
                         :page-size="page.size"
                         layout="total, sizes, prev, pager, next"
                         :total="page.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog append-to-body
      :visible.sync="getDataDialogVisible"
      width="30%"
      title="手动取数"
      :before-close="handleCancelGetDataDialog"
    >
      <div class="dialog-wrap">
        <div class="dialog-form" v-loading="dialogLoading">
          <el-form v-model="getDataForm">
            <div class="form-item">
              <label>组织名称</label>
              <el-form-item>
                <organization-select :model="getDataForm"></organization-select>
              </el-form-item>
            </div>
            <div class="form-item">
              <label>所属税期</label>
              <el-form-item>
                <el-date-picker v-model="getDataForm.declarePeriod"
                                type="month"
                                value-format="yyyyMM"
                                :picker-options="endDateOption"
                                style="width: 200px;"
                                placeholder="选择所属税期">
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form>
          <el-form class="dialog-footer">
            <el-button type="default" @click="handleCancelGetDataDialog">取消</el-button>
            <el-button type="primary" @click="handleGetDeclareData" :disabled="handleButtonStatus">确认
            </el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  dataEntList,
} from '@/service/declare/index'
import OrganizationSelect from '@/components/widgets/OrganizationSelect'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'

export default {
  name: 'entDataTmpl',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  components: { OrganizationSelect },
  data () {
    return {
      selectForm: {
        orgName: '',
        taxNo: ''
      },
      //取数弹窗表单
      getDataForm: {
        orgId: '',
        declarePeriod: ''
      },
      dialogLoading: false,
      tableData: [],
      tableLoading: false,
      hasChildren: true,
      getDataDialogVisible: false,
      endDateOption: {
        disabledDate (time) {
          if (new Date().getFullYear() == time.getFullYear()) {
            return time.getMonth() >= new Date().getMonth()
          }
        }
      },
    }
  },
  created () {
    this.submitForm()
  },
  computed: {
    handleButtonStatus () {
      let form = this.getDataForm
      return form.declarePeriod == '' || form.orgId == '' || form.orgId == null || form.declarePeriod == null
    }
  },
  methods: {
    handleSelect () {
      this.submitForm()
    },
    submitForm () {
      this.selectForm.page = this.page.current
      this.selectForm.size = this.page.size
      this.tableLoading = true
      dataEntList(this.selectForm).then(res => {
        this.tableLoading = false
        if (res.success) {
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    /* 查看详情 */
    handleDetail (row) {
      // let name = atob(row.orgName)
      //let id = atob(row.tableId)
      this.$router.replace({
        path: '/declare/setting/detail',
        query: {
          name: encodeURIComponent(row.orgName),
          id: encodeURIComponent(row.tableId)
        }
      })
    },
    //重置
    handleReset () {
      this.selectForm = {
        orgName: '',
        taxNo: ''
      }
    },
    //手动取数
    handleManualNumber () {
      this.getDataDialogVisible = true
    },
    handleGetDeclareData () {
    },
    //获取row-key
    getRowKeys (row) {
      return row.declarePeriod + row.id + row.orgId
    },
    //取消手动取数弹窗
    handleCancelGetDataDialog () {
      this.getDataDialogVisible = false
    },
    //设置单元列格化
    cellStyle ({ columnIndex }) {
      if (columnIndex === 1) {
        return 'text-align:left'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/style/select.scss";

  .select-content {
    .select-form {
      width: 55%;
    }

    .select-button {
      flex: none;
    }
  }

  .content-main {
    .content-title {
      display: flex;
      justify-content: space-between;
      color: #666666;
      font-size: 16px;
      font-weight: 400;
      font-family: Medium;
      margin-bottom: 12px;
    }

    .content-table {
      ::v-deep .el-button {
        padding: 0;
      }
    }
  }

  .dialog-form {
    margin: -48px 24px 0px;

    .form-item {
      margin-top: 24px;
      text-align: left;

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        margin-bottom: 5px;
      }
    }

    label:before {
      content: "*";
      color: #F5222D;
      margin-right: 2px;
    }

    .dialog-footer {
      margin-top: 32px;
      text-align: right;
      padding-bottom: 24px;
    }

    ::v-deep .el-select .el-input {
      width: 410px;
    }

    ::v-deep .el-input__inner {
      width: 410px;
    }
  }

</style>
